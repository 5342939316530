import React from "react"
import Style from "./bigGameCard.module.scss"
import ColorFullButton from "../colorfulButton"
import { GatsbyImage } from "../GatsbyImage"
import { Link } from "gatsby"
function BigGameCard({ data }) {

  return (
    <div className={Style.gameLandingContainer}>
      <div className={Style.fullWidthGameCard}>
        <GatsbyImage className={Style.img} name={data.image} alt="new" />
        <div>
          <h2 className={Style.heading}>{data.name}</h2>
          <a href={data.gamePath}>
            <ColorFullButton otherClassName={Style.btn} text="play now" />
          </a>

          {/* <Link to="play">
            <ColorFullButton otherClassName={Style.btn} text="play now" />
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default BigGameCard
