import React from "react"
import BigGameCard from "../components/bigGameCard"
import HomeLayout from "../layout/homeLayout"
import SEO from "../components/seo"

function GameLandingPage({ pageContext }) {
  console.log(pageContext)
  return (
    <HomeLayout>
      <SEO
        title={`${pageContext.data.name} | Gangs of Gamer Free online HTML 5 games`}
      />
      <BigGameCard data={pageContext.data} />
    </HomeLayout>
  )
}

export default GameLandingPage
